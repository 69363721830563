import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import { getUserDetail } from "../services/userManagementService";
import { getAllGroups } from "../services/userManagementService";


const Groups = () => {
  const intl = useIntl();
  const [allusers, setUsers] = useState();
  const [allgroups, setGroups] = useState();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();


  useEffect(() => {
    getAllGroups().then((result) => {
      setGroups(result);
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   getUsers().then((result) => {
  //     setUsers(result);
  //     setLoading(false);
  //   }).catch((error) => {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   });
  // }, []);

  // useEffect(() => {
  //   getUser('05aba26b-d0c5-41f8-b44a-2b684c9ac34d').then((result) => {
  //     setUser(result);
  //     setLoading(false);
  //   }).catch((error) => {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   });
  // }, []);

  return (
    <Layout mainClassName="container-fluid">
      <p>Test data: 
        {/* {JSON.stringify(allusers)} */}
        {/* {JSON.stringify(user)} */}
        {/* {JSON.stringify(allgroups)} */}
      </p>

      {/* {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
              <li>{user?.userId} - {user?.displayName}</li>
          </ul>
        )} */}

        {/* {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
          {allusers?.data.map((user) => (
              <li key={user.userId}>{user.userId} - {user.displayName} {user.companyName} - {user.identities[0].issuerAssignedId}</li>
          ))}
          </ul>
        )}

         {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
          {allgroups?.data.map((group) => (
              <li key={group.id}>{group.id} - {group.displayName}</li>
          ))}
          </ul>
        )} */}
          
    </Layout>
  );
};

export default Groups;
